import {Api} from "./Api"
import axios from "axios"
import {ISale} from "../interfaces/ISale"

export class SaleService extends Api {
    protected PATH = 'v1/Sale'

    get = async() => {
        const groups = await axios<ISale[]>(`${this.URL}/${this.PATH}`)
        return groups.data
    }

}