import {Api} from "./Api"
import axios from "axios"
import {ISettings} from "../interfaces/Settings"

export class SettingsService extends Api {
    protected PATH = 'v1/SiteSetting'

    get = async(key: string) => {
        const groups = await axios<ISettings[]>(`${this.URL}/${this.PATH}`)
        return groups.data
    }

}