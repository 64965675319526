import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomer } from "../../interfaces/Customer";
interface UserState {
    user: ICustomer | null
}
const initialState: UserState = {
    user: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<ICustomer | null>) => {
            state.user = action.payload
        }
    }
})


export const userReducer = userSlice.reducer
export const userActions = userSlice.actions