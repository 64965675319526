import classes from './Profile.module.css'
import { Await, NavLink, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import Page from '../Page/Page'
import React, { Suspense, useEffect } from 'react'
import AW from '../../assets/images/apple-wallet.svg'
import GW from '../../assets/images/google-wallet.svg'
import { ICustomer } from '../../interfaces/Customer'
import { Loader } from '../../components/loader/Loader'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { userActions } from '../../store/reducers/userReducer'
import { backCartToFrontCart } from '../../utils/BackendCartToFrontCart'
import { cartActions } from '../../store/reducers/cartReducer'
import { Helmet } from 'react-helmet-async'
import { useTypedSelector } from '../../hooks/useTypedSelector'

type PromiseType = {
    customer: Promise<ICustomer>
    balance: Promise<number>
}
export type CustomerContextType = {
    customer: ICustomer
}
const Main = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { customer, balance } = useLoaderData() as PromiseType
    const [cookie, setCookie, removeCookie] = useCookies(['token'])
    const dispatch = useDispatch()
    const { cart: oldCart } = useTypedSelector((state) => state.cartReducer)
    useEffect(() => {
        if (location.pathname === '/account') {
            navigate('/account/profile')
        }
        customer.then((user) => {
            dispatch(userActions.setUser(user))
            if (oldCart.length > 0) dispatch(cartActions.setCart(oldCart))
            else backCartToFrontCart(user.cart.products).then((cart) => dispatch(cartActions.setCart(cart)))
        })
    }, [])

    return (
        <Page>
            <Helmet prioritizeSeoTags>
                <title>Личная информация</title>
                <link rel='canonical' href='https://parfumeratelier.ru/account/profile' />
                <meta property='og:title' content='Личная информация' />
                <meta property='og:url' content='https://parfumeratelier.ru/account/profile' />
                <meta property='og:description' content='Личная информация покупателя в магазине Parfumer Atelier' />
                <meta name='description' content='Личная информация покупателя в магазине Parfumer Atelier' />
            </Helmet>
            <Suspense fallback={<Loader />}>
                <Await resolve={Promise.all([customer, balance])} errorElement={<ErrorElement msg='Не удалось загрузить профиль' />}>
                    {([user, balance]: [ICustomer, number]) => {
                        return (
                            <div className={classes.profile}>
                                <div className={classes.sidebar}>
                                    <div className={classes.name}>
                                        <h1>
                                            {`${user.firstName} ${user.middleName || ''}`.trim()}
                                            <br />
                                            {user.lastName}
                                        </h1>
                                        <div className={classes.bonuses}>
                                            <div>
                                                Бонусы
                                                <span className={classes.count}>{`${balance.toFixed(2)}₽`}</span>
                                            </div>
                                            <div>
                                                Кэшбэк
                                                <span className={classes.count}>{`${user.bonusData.category || '0%'}`}</span>
                                            </div>
                                        </div>

                                        <div className={classes.wallets}>
                                            <a href={user.wallet.card} target='_blank' rel='nofollow noopener'>
                                                <img src={AW} alt='Apple Wallet' />
                                            </a>
                                            <a href={`https://pay.google.com/gp/v/save/${user.googleWallet}`} target='_blank' rel='nofollow noopener'>
                                                <img src={GW} alt='Google Wallet' />
                                            </a>
                                        </div>

                                        <nav role='navigation' className={classes.links}>
                                            <NavLink to='/account/profile' className={({ isActive }) => (isActive ? classes.active : undefined)}>
                                                Личная информация
                                            </NavLink>
                                            <NavLink to='/account/favorite' className={({ isActive }) => (isActive ? classes.active : undefined)}>
                                                Избранное
                                            </NavLink>
                                            <NavLink to='/account/orders' className={({ isActive }) => (isActive ? classes.active : undefined)}>
                                                Мои покупки
                                            </NavLink>
                                            <a href='https://wa.me/message/M57AZ5K4Y4NRE1' target='_blank' rel='nofollow noopener'>
                                                Чат с консультантом
                                            </a>
                                            {/* <NavLink
                                                to='/account/settings'
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? classes.active
                                                        : undefined
                                                }>
                                                Настройки
                                            </NavLink> */}
                                            <NavLink
                                                to='/'
                                                onClick={() => {
                                                    removeCookie('token', { path: '/', secure: true })
                                                    dispatch(userActions.setUser(null))
                                                    dispatch(cartActions.setCart([]))
                                                }}>
                                                Выход
                                            </NavLink>
                                        </nav>
                                    </div>
                                </div>

                                <div className={classes.content}>
                                    <Outlet context={{ customer: user }} />
                                </div>
                            </div>
                        )
                    }}
                </Await>
            </Suspense>
        </Page>
    )
}

export default Main
