import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { ISlide, IStory } from '../../interfaces/Story'
import cls from './StoriesSlider.module.css'
import { StorySliderFullscreen } from './StorySliderFullscreen'

interface StoriesSliderProps {
    stories: IStory[]
}
export const StoriesSlider = ({ stories }: StoriesSliderProps) => {
    const [currentStoryIndex, setStoryIndex] = useState<number | null>(null)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (currentStoryIndex !== null && currentStoryIndex >= 0)
            document.body.setAttribute('style', 'overflow: hidden')
        else document.body.setAttribute('style', 'overflow: auto')
    }, [currentStoryIndex])
    return (
        <>
            <div className={cls.Slider}>
                <div className={cls.SliderInner}>
                    {stories.map((story, i) => (
                        <div
                            key={story._id}
                            className={cls.SliderItem}
                            onClick={() => {
                                setStoryIndex(i)
                                setOpen(true)
                            }}>
                            <div
                                className={clsx(
                                    cls.SliderItemCircle,
                                    localStorage
                                        .getItem('viewedStories')
                                        ?.split(',')
                                        .includes(story._id) && cls.Viewed
                                )}>
                                <img src={story.preview} />
                            </div>
                            <span>{story.name}</span>
                        </div>
                    ))}
                </div>
            </div>
            {open && (
                <StorySliderFullscreen
                    activeStoryIndex={currentStoryIndex!}
                    stories={stories}
                    close={() => {
                        setStoryIndex(null)
                        setOpen(false)
                    }}
                />
            )}
        </>
    )
}
