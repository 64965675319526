import React, { Suspense } from 'react'
import {Await, Link, useLoaderData} from 'react-router-dom'
import classes from "./Profile.module.css"
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { Loader } from '../../components/loader/Loader'
import { IFavorite } from '../../interfaces/Customer'
import {Helmet} from "react-helmet-async"
import {getMinPrice} from "../../utils/getMinPrice"
import {Item} from "../../components";

type PromiseType = {
    favorite: Promise<IFavorite[]>
}

const Favorite = () => {
    const { favorite } = useLoaderData() as PromiseType

    return (
        <div>
            <h2>Избранное</h2>
            <Helmet prioritizeSeoTags>
                <title>Избранное</title>
                <link rel="canonical" href="https://parfumeratelier.ru/account/favorite" />
                <meta name="description" content="Избранные товары в магазине парфюмерии Parfumer Atelier" />
            </Helmet>
            <Suspense fallback={<Loader />}>
                <Await
                    resolve={favorite}
                    errorElement={
                        <ErrorElement msg='Не удалось загрузить избранное' />
                    }>
                    {(favorite: IFavorite[]) => {
                        return (
                            <div className={classes.items}>{
                                favorite[0]?.products.map((i) => {
                                    const [priceDefault, priceSale] = getMinPrice(i)
                                    return (
                                        <Item
                                            image={i.imgUrl[0]}
                                            name={i.name}
                                            price={priceDefault}
                                            salesPrice={priceSale}
                                            // badges={i.badges}
                                            categories={i.categories}
                                            slug={i.slug} />
                                    )
                                })
                            }</div>
                        )
                    }}
                </Await>
            </Suspense>
        </div>
    )
}

export default Favorite
