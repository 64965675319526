import React, { useEffect, useState, useRef, createRef } from 'react'
import { ITagGroup, ITag } from '../../interfaces/TagGroup'
import classes from './sidebar.module.css'
import NumberSlider from '../numberSlider/numberSlider'
import Arrow from '../../assets/images/arrow_cat.svg'
import clsx from 'clsx'
import { IBrand } from '../../interfaces/Brand'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import FemAroma from '../../assets/images/fem_aroma.png'
import MaleAroma from '../../assets/images/male_aroma.png'
import UniAroma from '../../assets/images/uni_aroma.png'
type Props = {
    groups: Array<ITagGroup>
    brands: Array<IBrand>
    onFilterChange: (filter: ITag[]) => void
    onPriceFilterChange: ([min, max]: number[]) => void
    onBrandsChange: (brands: IBrand[]) => void
    setOpenState: React.Dispatch<React.SetStateAction<boolean>>
    activeSortType: ISortType
    onSortTypeChange: (sortType: ISortType) => void
}

interface IGroup {
    id: string
    isOpen: boolean
}

type FilterTags = {
    [key: string]: ITag[]
}
export interface ISortType {
    id: string
    name: string
}
const sortList: ISortType[] = [
    // {
    //     id: 'popular',
    //     name: 'по популярности',
    // },
    // {
    //     id: 'sale',
    //     name: 'по величине скидки',
    // },
    {
        id: 'default',
        name: 'по умолчанию',
    },
    {
        id: 'name_asc',
        name: 'по имени (А-Я)',
    },
    {
        id: 'name_desc',
        name: 'по имени (Я-А)',
    },
    {
        id: 'price_desc',
        name: 'по убыванию цены',
    },
    {
        id: 'price_asc',
        name: 'по возрастанию цены',
    },
]

const Sidebar: React.FC<Props> = ({
    groups,
    brands,
    onFilterChange,
    onBrandsChange,
    onPriceFilterChange,
    setOpenState,
    activeSortType,
    onSortTypeChange,
}) => {
    const refs = useRef(Array.from({ length: groups.length }, (a) => createRef<HTMLDivElement>()))

    //new
    const [groupsOpen, setGroupsOpen] = useState<string[]>([])

    const [brandsDiv, setBrandsDiv] = useState(false)
    const [filterIsActive, setFilterIsActive] = useState(false)
    const [brandsFilterIsActive, setBrandsFilterIsActive] = useState(false)
    const [groupsFilterIsActive, setGroupsFilterIsActive] = useState<ITagGroup | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()

    const [visibleBrands, setVisibleBrands] = useState<IBrand[]>(brands)
    const [brandsSearch, setBrandsSearch] = useState('')

    const location = useLocation()

    useEffect(() => {
        if (brandsSearch.length > 2) {
            setVisibleBrands((prev) => {
                return prev.filter(
                    (b) => b.name.toLowerCase().indexOf(brandsSearch.toLowerCase()) !== -1
                )
            })
        } else setVisibleBrands(brands)
    }, [brandsSearch, brands])

    useEffect(() => {
        const tags: ITag[] = []
        let filterPrice = [-1, -1]
        const filterBrands = []
        for (const [key, value] of searchParams) {
            if (key === 'price') {
                filterPrice = value.split(',').map((v) => Number(v))
                continue
            }
            if (key === 'brands') {
                const _brand = brands.find((b) => b.slug === value)
                if (_brand) {
                    filterBrands.push(_brand)
                    addBrand(_brand)
                }
                continue
            }
            const group = groups.find((g) => g.slug === key)

            if (group) {
                const values = value.split(',')
                setGroupsOpen((prev) => [...prev, group.slug])
                for (const v of values) {
                    const tag = group.tags.find((t) => v === t.slug)
                    if (tag) {
                        if (tags.find((t) => tag._id === t._id)) continue
                        addFilterTag(group.slug, tag)
                        tags.push(tag)
                    }
                }
            }
        }
        // if (filterBrands.length > 0) {
        //     setBrandsDiv(true)
        // }
        onBrandsChange(filterBrands)
        onPriceFilterChange(filterPrice)
        setFilterPrice(filterPrice)
        onFilterChange(tags)
    }, [groups, brands])

    useEffect(() => {
        if (filterIsActive) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'
    }, [filterIsActive])

    const onGroupHeadClick = (slug: string) => {
        // const newGroups = groupDivs.map((g) => {
        //     if (g.id === slug) {
        //         g.isOpen = !g.isOpen
        //     }
        //     return g
        // })

        // setGroupDivs(newGroups)
        if (groupsOpen.includes(slug)) setGroupsOpen((prev) => prev.filter((g) => g !== slug))
        else setGroupsOpen((prev) => [...prev, slug])
    }

    const checkOpen = (slug: string): boolean => {
        // const div = groupDivs.find((t) => t.id === slug)
        // if (div) return !div.isOpen
        // return false

        return !groupsOpen.includes(slug)
    }

    const [filterTags, setFilterTags] = useState<FilterTags>({})
    const [filterPrice, setFilterPrice] = useState<number[]>([-1, -1])
    const [brandsSlug, setBrandsSlug] = useState<IBrand[]>([])

    const addFilterTag = (groupSlug: string, tag: ITag) => {
        // setFilterTags((prev) => [...prev, tag])

        setFilterTags((prev) => ({
            ...prev,
            [groupSlug]: prev[groupSlug] ? [...prev[groupSlug], tag] : [tag],
        }))
    }
    const removeFilterTag = (groupSlug: string, tags: string[]) => {
        // setFilterTags((prev) => prev.filter((p) => tag._id !== p._id))
        setFilterTags((prev) => ({
            ...prev,
            [groupSlug]: prev[groupSlug]
                ? prev[groupSlug].filter((p) => !tags.includes(p.slug))
                : [],
        }))
    }

    const addBrand = (brand: IBrand) => {
        setBrandsSlug((prev) => [...prev, brand])
    }
    const removeBrand = (brand: IBrand) => {
        setBrandsSlug((prev) => prev.filter((b) => brand._id !== b._id))
    }

    const sortBrands = (a: IBrand, b: IBrand) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (nameA < nameB) return -1

        if (nameA > nameB) return 1

        return 0
    }

    const getGroupById = (id: String): ITagGroup | undefined => {
        // console.log(groups)
        const g = groups.find((g) => g._id === id)
        // console.log(g)
        return g
    }

    const submitSearch = () => {
        console.log(filterTags)
        const queryTags: { [key: string]: ITag[] | string[] } = { ...filterTags }
        for (const key in queryTags) {
            queryTags[key] = queryTags[key].map((value) => (value as ITag).slug)
        }
        setSearchParams({
            price: filterPrice.toString(),
            brands: brandsSlug.map((b) => b.slug),
            ...queryTags,
        })
        // setSearchParams(Object.keys(filterTags).reduce((acc, o) => ({ ...acc, [o]: filterTags[o].map((t) => t.slug).join(',') }), {}))
        onFilterChange(Object.values(filterTags).reduce((acc, t) => [...acc, ...t], []))
        onPriceFilterChange(filterPrice)
        onBrandsChange(brandsSlug)
        setVisibleBrands(brands)
        setBrandsSearch('')
    }

    return (
        <>
            {location.pathname.split('/').includes('parfyumeriya') && (
                <div className={classes.GenderMobile}>
                    <button
                        onClick={() => {
                            const male = groups
                                .find((g) => g.slug === 'dlya-kogo')
                                ?.tags.find((t) => t.slug === 'muzhskoj') as ITag
                            removeFilterTag('dlya-kogo', ['zhenskij'])
                            addFilterTag('dlya-kogo', male)
                            const newFilterTags = {
                                ...filterTags,
                                'dlya-kogo': [male],
                            }
                            onFilterChange(
                                Object.values(newFilterTags).reduce((acc, t) => [...acc, ...t], [])
                            )
                            onPriceFilterChange(filterPrice)
                            onBrandsChange(brandsSlug)
                            setBrandsFilterIsActive(false)
                            setGroupsFilterIsActive(null)
                            setFilterIsActive(false)
                        }}>
                        <img src={MaleAroma} />
                        Мужские ароматы
                    </button>
                    <button
                        onClick={() => {
                            const female = groups
                                .find((g) => g.slug === 'dlya-kogo')
                                ?.tags.find((t) => t.slug === 'zhenskij') as ITag

                            removeFilterTag('dlya-kogo', ['muzhskoj'])
                            addFilterTag('dlya-kogo', female)
                            const newFilterTags = {
                                ...filterTags,
                                'dlya-kogo': [female],
                            }
                            onFilterChange(
                                Object.values(newFilterTags).reduce((acc, t) => [...acc, ...t], [])
                            )
                            onPriceFilterChange(filterPrice)
                            onBrandsChange(brandsSlug)
                            setBrandsFilterIsActive(false)
                            setGroupsFilterIsActive(null)
                            setFilterIsActive(false)
                        }}>
                        <img src={FemAroma} />
                        Женские ароматы
                    </button>
                    <button
                        onClick={() => {
                            const uni = groups
                                .find((g) => g.slug === 'dlya-kogo')
                                ?.tags.find((t) => t.slug === 'uniseks') as ITag

                            removeFilterTag('dlya-kogo', ['muzhskoj', 'zhenskij'])
                            addFilterTag('dlya-kogo', uni)
                            const newFilterTags = {
                                ...filterTags,
                                'dlya-kogo': [uni],
                            }
                            onFilterChange(
                                Object.values(newFilterTags).reduce((acc, t) => [...acc, ...t], [])
                            )
                            onPriceFilterChange(filterPrice)
                            onBrandsChange(brandsSlug)
                            setBrandsFilterIsActive(false)
                            setGroupsFilterIsActive(null)
                            setFilterIsActive(false)
                        }}>
                        <img src={UniAroma} />
                        Унисекс
                    </button>
                </div>
            )}
            <nav role='menu' className={classes.sidebarMobile} data-block='filter'>
                <button
                    className={classes.filterBtn}
                    onClick={() => setFilterIsActive(!filterIsActive)}>
                    фильтры
                </button>
            </nav>
            <aside className={clsx(classes.filters, { [classes.filtersIsActive]: filterIsActive })}>
                {!brandsFilterIsActive && !groupsFilterIsActive ? (
                    <>
                        <NumberSlider
                            min={filterPrice[0] === -1 ? 0 : filterPrice[0]}
                            max={filterPrice[1] === -1 ? 100_000 : filterPrice[1]}
                            onChange={(min, max) => setFilterPrice([min, max])}
                        />
                        {location.pathname.split('/').includes('parfyumeriya') && (
                            <nav role='navigation'>
                                <ul>
                                    <li onClick={() => setBrandsFilterIsActive(true)}>Бренды</li>
                                    {groups.map((g) =>
                                        g._id === '6391c74fe710367b407b80e0' ||
                                        g._id === '63452467c94f4f573e5fd760' ||
                                        g.isDeleted ||
                                        g.isDisabled ? null : (
                                            <li
                                                key={g._id}
                                                onClick={() => setGroupsFilterIsActive(g)}>
                                                {g.name}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </nav>
                        )}
                    </>
                ) : null}

                {brandsFilterIsActive ? (
                    <div
                        id='mobile-brands'
                        className={classes.groupTags}
                        style={{ overflowY: 'scroll', marginBottom: '1rem' }}>
                        <input
                            className='input'
                            placeholder='Поиск...'
                            value={brandsSearch}
                            onChange={({ target }) => setBrandsSearch(target.value)}
                        />
                        {brands &&
                            visibleBrands.sort(sortBrands).map((b: IBrand) => (
                                <div key={b._id} className={classes.groupTag}>
                                    <input
                                        type='checkbox'
                                        className='checkbox'
                                        id={`brands+${b.slug}`}
                                        name={b.slug}
                                        value={b.slug}
                                        onChange={(e) => {
                                            if (e.target.checked) addBrand(b)
                                            else removeBrand(b)
                                        }}
                                    />
                                    <label htmlFor={`brands+${b.slug}`}>{b.name}</label>
                                </div>
                            ))}
                    </div>
                ) : null}

                {groupsFilterIsActive ? (
                    <div
                        className={classes.groupTags}
                        style={{ overflowY: 'scroll', marginBottom: '1rem' }}>
                        {
                            //@ts-ignore

                            groupsFilterIsActive.tags.map((t: ITag) => (
                                <div key={t._id} className={classes.groupTag}>
                                    <input
                                        type='checkbox'
                                        className='checkbox'
                                        id={`${groupsFilterIsActive.slug}+${t.slug}`}
                                        name={groupsFilterIsActive.slug}
                                        value={t.slug}
                                        checked={
                                            !!filterTags[groupsFilterIsActive.slug]?.find(
                                                (ft) => ft._id === t._id
                                            )
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked)
                                                addFilterTag(groupsFilterIsActive.slug, t)
                                            else
                                                removeFilterTag(groupsFilterIsActive.slug, [t.slug])
                                        }}
                                    />
                                    <label htmlFor={`${groupsFilterIsActive.slug}+${t.slug}`}>
                                        {t.name}
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                ) : null}

                <div className={classes.filtersButton}>
                    {!brandsFilterIsActive && !groupsFilterIsActive ? (
                        <button
                            onClick={() => {
                                setBrandsFilterIsActive(false)
                                setGroupsFilterIsActive(null)
                                setBrandsSlug([])
                                setFilterTags({})
                                setFilterPrice([-1, -1])
                                setFilterIsActive(false)

                                setVisibleBrands(brands)
                                setBrandsSearch('')
                                onFilterChange([])
                                onPriceFilterChange([-1, -1])
                                onBrandsChange(brandsSlug)
                            }}
                            className={classes.resetBtn}>
                            Сбросить
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                setBrandsFilterIsActive(false)
                                setGroupsFilterIsActive(null)
                            }}
                            className={classes.resetBtn}>
                            Назад
                        </button>
                    )}
                    <button
                        onClick={() => {
                            onFilterChange(
                                Object.values(filterTags).reduce((acc, t) => [...acc, ...t], [])
                            )
                            onPriceFilterChange(filterPrice)
                            onBrandsChange(brandsSlug)
                            setBrandsFilterIsActive(false)
                            setGroupsFilterIsActive(null)
                            setFilterIsActive(false)
                        }}
                        className={classes.applyBtn}>
                        Применить
                    </button>
                </div>
            </aside>
            <nav role='menu' className={clsx(classes.sidebar)}>
                <div className={clsx('select-box', classes.selectBox)}>
                    <div className='select-box__current' tabIndex={1}>
                        {sortList.map((s) => (
                            <div className='select-box__value' key={s.id}>
                                <input
                                    className='select-box__input'
                                    type='radio'
                                    id={s.id}
                                    value={s.name}
                                    onChange={() => onSortTypeChange(s)}
                                    name='sort'
                                    checked={s.id === activeSortType.id}
                                />
                                <p className='select-box__input-text'>{s.name}</p>
                            </div>
                        ))}
                        <img
                            className='select-box__icon'
                            src={Arrow}
                            alt='Arrow Icon'
                            aria-hidden='true'
                        />
                    </div>
                    <ul className='select-box__list'>
                        {sortList.map((sl) => (
                            <li key={`li_${sl.id}`}>
                                <label
                                    className='select-box__option'
                                    htmlFor={sl.id}
                                    aria-hidden='true'>
                                    {sl.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <NumberSlider
                    min={filterPrice[0] === -1 ? 0 : filterPrice[0]}
                    max={filterPrice[1] === -1 ? 100_000 : filterPrice[1]}
                    onChange={(min, max) => setFilterPrice([min, max])}
                />
                {location.pathname.split('/').includes('parfyumeriya') && (
                    <>
                        {!location.pathname.split('/').includes('brands') && (
                            <div
                                className={clsx(classes.group, {
                                    [classes.closed]: !brandsDiv,
                                })}>
                                <div
                                    className={classes.groupHead}
                                    onClick={() => setBrandsDiv(!brandsDiv)}>
                                    <h4>Бренды</h4>
                                    <img src={Arrow} alt='Свернуть/Раскрыть' />
                                </div>
                                <div id='brands' className={classes.groupTags}>
                                    <input
                                        className='input'
                                        placeholder='Поиск...'
                                        value={brandsSearch}
                                        onChange={({ target }) => setBrandsSearch(target.value)}
                                    />
                                    {brands &&
                                        visibleBrands.sort(sortBrands).map((b: IBrand) => (
                                            <div key={b._id} className={classes.groupTag}>
                                                <input
                                                    type='checkbox'
                                                    className='checkbox'
                                                    id={`brands+${b.slug}`}
                                                    name={b.slug}
                                                    value={b.slug}
                                                    checked={
                                                        !!brandsSlug.find((_b) => b._id === _b._id)
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) addBrand(b)
                                                        else removeBrand(b)
                                                    }}
                                                />
                                                <label htmlFor={`brands+${b.slug}`}>{b.name}</label>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}

                        {groups.map((g, i) => {
                            return g._id === '6391c74fe710367b407b80e0' ||
                                g._id === '63452467c94f4f573e5fd760' ||
                                g.isDeleted ||
                                g.isDisabled ? null : (
                                <div
                                    key={g._id}
                                    ref={refs.current[i]}
                                    className={clsx(classes.group, {
                                        [classes.closed]: checkOpen(g.slug),
                                    })}>
                                    <div
                                        className={classes.groupHead}
                                        onClick={(e) => onGroupHeadClick(g.slug)}>
                                        <h4>{g.name}</h4>
                                        <img src={Arrow} alt='Свернуть/Раскрыть' />
                                    </div>
                                    <div id={g.slug} className={classes.groupTags}>
                                        {g.tags.map((t: ITag) => (
                                            <div key={t._id} className={classes.groupTag}>
                                                <input
                                                    type='checkbox'
                                                    className='checkbox'
                                                    id={`${g.slug}+${t.slug}`}
                                                    name={g.slug}
                                                    value={t.slug}
                                                    checked={
                                                        !!filterTags[g.slug]?.find(
                                                            (ft) => ft._id === t._id
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked)
                                                            addFilterTag(g.slug, t)
                                                        else removeFilterTag(g.slug, [t.slug])
                                                    }}
                                                />
                                                <label htmlFor={`${g.slug}+${t.slug}`}>
                                                    {t.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}
                <button className={classes.applyBtn} onClick={submitSearch}>
                    Применить
                </button>
                <button
                    onClick={() => {
                        setBrandsSlug([])
                        setFilterTags({})
                        setFilterPrice([-1, -1])
                        onFilterChange([])
                        onPriceFilterChange([-1, -1])
                        onBrandsChange(brandsSlug)
                        setVisibleBrands(brands)
                        setBrandsSearch('')
                    }}
                    className={classes.resetBtn}
                    style={{ marginTop: '10px' }}>
                    Сбросить
                </button>
            </nav>
        </>
    )
}

export default Sidebar
