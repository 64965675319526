import React, {FC, useEffect} from 'react'
import {useRouteError} from "react-router-dom";

type ErrorElementProps = {
    msg: string
}
export const ErrorElement: FC<ErrorElementProps> = ({ msg }) => {

    const err = useRouteError()

    useEffect(() => {
        //
    }, [err])

    return <div>{msg}</div>
}
