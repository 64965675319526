import { Api } from './Api'
import {axiosInstance} from "./axios"
import {ICategory} from "../interfaces/Category"

export class CategoryService extends Api {
    protected PATH = 'v1/category'

    getCategories = async(slug?: string) => {
        const req = await axiosInstance.get<ICategory[]>(`${this.URL}/${this.PATH}/`, {
            params: {
                slug
            }
        })
        return req.data
    }
}