import React, {useEffect, useState} from "react"
import classes from "./itemsGroup.module.css"
import Arrow from "../../assets/images/arrow.svg"
import Item from "../item/item"
import {IItem} from "../../interfaces/Item"
import {getMinPrice} from "../../utils/getMinPrice"

type ItemsGroupProps = {
    title: string
    items: Array<IItem>
    perPage?: number
}

const ItemsGroup: React.FC<ItemsGroupProps> = ({title, items, perPage = 3}) => {

    const [page, setPage] = useState(0)
    const [slicedItems, setSlicedItems] = useState<Array<IItem>>([])
    const [processedPerPage, setProcessedPerPage] = useState(perPage)

    useEffect(() => {
        if (window.document.body.getBoundingClientRect().width < 768)
            setProcessedPerPage(1)
    }, [])

    useEffect(() => {
        let start = page * processedPerPage
        let end = page * processedPerPage + processedPerPage

        setSlicedItems(items.slice(start, end))
    }, [page, items, processedPerPage])

    const onNextClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if ((page + 1) * processedPerPage < items.length)
            setPage(page => ++page)
        else
            setPage(0)
    }

    const onPrevClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if (page > 0)
            setPage(page => --page)
        else {
            setPage(Math.ceil(items.length / processedPerPage) - 1)
        }
    }

    return (
        <div className={classes.itemsGroup}>
            <div className={classes.header}>
                <div className={classes.empty} />
                <h2>{title}</h2>
                {
                    items.length > processedPerPage ? (
                        <div className={classes.arrows}>
                            <button className={classes.arrowPrev} onClick={onPrevClick}>
                                <img src={Arrow} alt="Prev" />
                            </button>
                            <button className={classes.arrowNext} onClick={onNextClick}>
                                <img src={Arrow} alt="Next" />
                            </button>
                        </div>
                    ) : null
                }
            </div>
            <div className={classes.items} style={{"--perPage": `repeat(${processedPerPage}, 1fr)`} as React.CSSProperties}>
                {
                    slicedItems.map((item, i) => {
                        const [priceDefault, priceSale] = getMinPrice(item)
                        return (
                            <Item
                                key={`itemsGroup_item_${i}`}
                                categories={item.categories}
                                salesPrice={priceSale}
                                image={item.imgUrl[0]}
                                name={item.name}
                                badges={item.badges}
                                price={priceDefault}
                                slug={item.slug}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ItemsGroup