import { Api } from './Api'
import { axiosInstance } from './axios'

export class TagService extends Api {
    protected TAG_GROUP = 'v1/tagGroup'
    protected TAG = 'v1/tag'
    
    getTagGroups = async() => {
            const groups = await axiosInstance.get<TagService[]>(`${this.URL}/${this.TAG_GROUP}?isDeleted=false&isDisabled=false`)
            return groups.data
    }
}