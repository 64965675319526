import { Api } from './Api'
import {axiosInstance} from "./axios"
import {ISlider} from "../interfaces/Slider"

export class SliderService extends Api {
    protected PATH = 'v1/slider'

    // fetch a main slider by id
    getSlider = async() => {
        const groups = await axiosInstance.get<ISlider[]>(`${this.URL}/${this.PATH}/`, {
            params: {
                _id: "635b9b4c92c1d96fac12aa87"
            }
        })
        return groups.data
    }
}