import Page from '../Page/Page'
import classes from './Promo.module.css'
import React, { Suspense } from 'react'
import clsx from 'clsx'
import { Await, Link, useLoaderData } from 'react-router-dom'
import { ISale } from '../../interfaces/ISale'
import { Loader } from '../../components/loader/Loader'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { DateTime } from 'ts-luxon'
import { Helmet } from 'react-helmet-async'
import Stocks from '../../assets/images/stocks.png'
type PromiseType = {
    promos: Array<Promise<ISale>>
}

const Promo = () => {
    const { promos } = useLoaderData() as PromiseType

    // @ts-ignore
    const chunkedPromo = (array, perChunk) => {
        // @ts-ignore
        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
    }

    return (
        <Page>
            <Helmet prioritizeSeoTags>
                <title>Акции</title>
                <link rel='canonical' href='https://parfumeratelier.ru/promo' />
                <meta name='description' content={`Акции на парфюмерию и аромабоксы в магазине Parfumer Atelier`} />
                <meta property='og:title' content={`Акции`} />
                <meta property='og:url' content={`https://parfumeratelier.ru/promo`} />
                <meta property='og:description' content={`Акции на парфюмерию и аромабоксы в магазине Parfumer Atelier`} />
            </Helmet>
            <div className={classes.banner} style={{ backgroundImage: `url(${Stocks})` }}></div>
            <div className={classes.event}>
                <div className={classes.header}>
                    <div className={classes.empty} />
                    <h2>Акции</h2>
                    <div />
                </div>
                <div className={classes.promos}>
                    <Suspense fallback={<Loader />}>
                        <Await resolve={promos} errorElement={<ErrorElement msg='Не удалось загрузить акции' />}>
                            {(ps: ISale[]) => {
                                return (
                                    <>
                                        {ps.map((p, i) => (
                                            <Link key={p._id} to={p.slug}>
                                                <div
                                                    className={clsx(classes.promo, {
                                                        [classes.last]: i === ps.length - 1,
                                                    })}>
                                                    <img src={p.image} alt={p.title} />
                                                    <div className={classes.content}>
                                                        {p.validity.from && p.validity.until ? (
                                                            <time>
                                                                с {DateTime.fromISO(p.validity.from).toFormat('dd.LL.y')} по {DateTime.fromISO(p.validity.until).toFormat('dd.LL.y')}
                                                            </time>
                                                        ) : (
                                                            // <time>
                                                            // с {DateTime.fromISO(p.validity.from).toFormat('dd.LL.y')} по {DateTime.fromISO(p.validity.from).toFormat('dd.LL.y')}
                                                            // </time>
                                                            <time>с {DateTime.fromISO(p.validity.from).toFormat('dd.LL.y')}</time>
                                                        )}
                                                        <h2>{p.title}</h2>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </>
                                )
                            }}
                        </Await>
                    </Suspense>
                </div>
            </div>
        </Page>
    )
}

export default Promo
