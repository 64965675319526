import Page from '../Page/Page'
import classes from './Catalog.module.css'
import gClasses from '../Page/Page.module.css'
import { Await, Link, Outlet, useLoaderData, useLocation } from 'react-router-dom'
import React, { Suspense, useEffect, useState } from 'react'
import { Sidebar } from '../../components'
import { ITag, ITagGroup } from '../../interfaces/TagGroup'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { Loader } from '../../components/loader/Loader'
import { IItem } from '../../interfaces/Item'
import { IBrand } from '../../interfaces/Brand'
import clsx from 'clsx'
import { Helmet } from 'react-helmet-async'
import { ISortType } from '../../components/sidebar/sidebar'
import BrandImg from '../../assets/images/brands.png'
export type BannerContextType = {
    setBanner: React.Dispatch<React.SetStateAction<string>>
    filters: ITag[]
    filterPrice: number[]
    pickedBrands: IBrand[]
    sortType: ISortType
}

type PromiseType = {
    brands: Promise<Array<IBrand>>
    items: Promise<Array<IItem>>
    groups: Promise<ITagGroup>
    dataType: string
}

interface BrandsMapProps {
    obj: Map<string, Array<IBrand>>
}

// const defaultBanner = 'https://storage.yandexcloud.net/parfum.public/media/Artboard.jpg'
const defaultBanner = BrandImg
const Catalog = () => {
    const { items, groups, brands } = useLoaderData() as PromiseType
    const [filters, setFilter] = useState<ITag[]>([])
    const [filterPrice, setFilterPrice] = useState<number[]>([-1, -1])
    const [pickedBrands, setPickedBrands] = useState<IBrand[]>([])
    const [banner, setBanner] = useState<string>(defaultBanner)
    const [openState, setOpenState] = useState(false)

    const [brandsArr, setBrandsArr] = useState<Array<IBrand>>([])

    const [brandSearch, setBrandSearch] = useState('')

    const [sortType, setSortType] = useState<ISortType>({
        id: 'default',
        name: 'по умолчанию',
    })

    const location = useLocation()

    useEffect(() => {
        Promise.resolve(brands).then((b) => {
            setBrandsArr(b)
        })
    }, [brands])

    const getLetters = (arr: Array<IBrand>): Array<string> => {
        const obj = new Map<string, Array<IBrand>>()
        arr.sort((a, b) => {
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1

            return 0
        })
        for (let a of arr) {
            let c = a.name.charAt(0).toUpperCase()
            if (obj.has(c)) {
                let newArr = obj.get(c)
                if (newArr) {
                    newArr.push(a)
                    obj.set(c, newArr)
                }
            } else {
                obj.set(c, [a])
            }
        }

        let brands: Array<string> = []
        for (const [key] of obj) {
            brands.push(key.toUpperCase())
        }

        return brands
    }

    const getHashBrands = (arr: Array<IBrand>, search: string): Array<JSX.Element> => {
        const obj = new Map<string, Array<IBrand>>()
        arr.sort((a, b) => {
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1

            return 0
        })
        for (let a of arr) {
            let c = a.name.charAt(0).toUpperCase()
            if (obj.has(c)) {
                let newArr = obj.get(c)
                if (newArr) {
                    newArr.push(a)
                    obj.set(c, newArr)
                }
            } else {
                obj.set(c, [a])
            }
        }

        let brands: Array<JSX.Element> = []
        for (const [key, value] of obj) {
            const values =
                search.length > 2
                    ? value.filter((v) => v.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                    : [...value]
            if (values.length !== 0) {
                const el = (
                    <div className={classes.brandContent}>
                        <div className={classes.brandAnchor} id={`letter-${key.toUpperCase()}`} />
                        <h2>{key.toUpperCase()}</h2>
                        {/*<div className={classes.brandProducts}>*/}
                        {values.map((v, i) => (
                            <p key={`brand_${i}`}>
                                <Link to={`/brands/${v.slug}`} className={classes.brandLink}>
                                    {v.name}
                                </Link>
                            </p>
                        ))}
                        {/*</div>*/}
                    </div>
                )

                brands.push(el)
            }
        }

        return brands
    }

    const onLetterClick = (e: React.MouseEvent, l: string) => {
        e.preventDefault()
        const el = document.getElementById(`letter-${l}`)
        if (el) {
            el.scrollIntoView(true)
            // setTimeout(() => {
            //     el.scrollBy(0, -120)
            // }, 250)
        }
    }
    return (
        <Page>
            <div
                className={classes.banner}
                style={
                    {
                        '--background': `url(${banner ? banner : defaultBanner})`,
                    } as React.CSSProperties
                }
            />
            <div className={gClasses.inner}>
                <div
                    className={clsx(classes.catalog, {
                        [classes.catalogBrands]: location.pathname === '/brands',
                        [classes.catalogSidebar]: openState,
                    })}>
                    {location.pathname !== '/brands' ? (
                        <Suspense fallback={<Loader />}>
                            <Await
                                resolve={groups}
                                errorElement={<ErrorElement msg='Не удалось загрузить фильтры' />}>
                                {(groups) => (
                                    <Sidebar
                                        groups={groups}
                                        brands={brandsArr}
                                        onBrandsChange={setPickedBrands}
                                        onFilterChange={(tags) => {
                                            setFilter(tags)
                                        }}
                                        onPriceFilterChange={setFilterPrice}
                                        setOpenState={setOpenState}
                                        onSortTypeChange={setSortType}
                                        activeSortType={sortType}
                                    />
                                )}
                            </Await>
                        </Suspense>
                    ) : (
                        <>
                            <Helmet prioritizeSeoTags>
                                <title>Бренды парфюмерии</title>
                                <link rel='canonical' href={`https://parfumeratelier.ru/brands}`} />
                                <meta
                                    name='description'
                                    content={`Заказывайте парфюмерию лучших брендов онлайн в интернет-магазине Parfumer Atelier. Широкий каталог духов известных брендов всего мира: европейских, азиатских, американских, российских.`}
                                />
                            </Helmet>
                            <Suspense fallback={<Loader />}>
                                <Await
                                    resolve={brands}
                                    errorElement={
                                        <ErrorElement msg='Не удалось загрузить бренды' />
                                    }>
                                    {(brands: Array<IBrand>) => {
                                        const bs = getHashBrands(brands, brandSearch)
                                        const alphabet = getLetters(brands)
                                        return (
                                            <>
                                                <div className={classes.title}>
                                                    <h1>Бренды</h1>
                                                </div>
                                                <div className={classes.brands}>
                                                    <div className={classes.alphabet}>
                                                        {alphabet.map((m) => (
                                                            <span
                                                                onClick={(e) =>
                                                                    onLetterClick(e, m)
                                                                }>
                                                                {m}
                                                            </span>
                                                        ))}
                                                    </div>
                                                    <input
                                                        className='input'
                                                        placeholder='Поиск...'
                                                        value={brandSearch}
                                                        onChange={({ target }) =>
                                                            setBrandSearch(target.value)
                                                        }
                                                    />
                                                    {bs.map((m, i) => (
                                                        <div key={`brandChar_${i}`}>{m}</div>
                                                    ))}
                                                </div>
                                            </>
                                        )
                                    }}
                                </Await>
                            </Suspense>
                        </>
                    )}

                    <Outlet context={{ setBanner, filters, filterPrice, pickedBrands, sortType }} />
                </div>
            </div>
        </Page>
    )
}

export default Catalog
