import axios, { AxiosError } from 'axios'
import { deleteCookie, getCookie } from '../utils/cookieHelper'
export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async req => {
    const token = getCookie('token')
    
    if(req.headers) {
        req.headers.Authorization = `Bearer ${token}`
    }
       
    return req
})

axiosInstance.interceptors.response.use(async res => {    
    return res
}, (error: AxiosError) => {
    if(error.response?.status === 401) {
        deleteCookie('token')
        window.dispatchEvent(new Event('storage'))
    }
    return Promise.reject(error)
})
