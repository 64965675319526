import { useEffect } from 'react'

const useTinkoff = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
}

export default useTinkoff