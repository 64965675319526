import { Api } from './Api'
import {axiosInstance} from "./axios"
import {IOrder, IProcessSale} from "../interfaces/Order"
import {IConfirmOrderResponse} from "../interfaces/ITinkoffReceipt";

export class OrderService extends Api {
    protected PATH = 'v1/order'

    processSale = async (order: IOrder) => {
        const groups = await axiosInstance.post<IProcessSale>(`${this.URL}/${this.PATH}/create`, {
            ...order
        })
        return groups.data
    }

    create = async (order: IOrder) => {
        const groups = await axiosInstance.post<IProcessSale>(`${this.URL}/${this.PATH}/create`, {
            ...order
        })
        return groups.data
    }

    confirm = async (): Promise<IConfirmOrderResponse> => {
        const groups = await  axiosInstance.post<IConfirmOrderResponse>(`${this.URL}/${this.PATH}/confirm`)
        return groups.data
    }

    availableBonuses = async(promocode?: string) => {
        let body = {}
        if(promocode) body = {
            promocode
        }
        const bonuses = await axiosInstance.post<{available_bonuses: number}>(`${this.URL}/${this.PATH}/available-bonuses`, body)
        return bonuses.data
    }
}