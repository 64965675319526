import { ICart, IKilbillCart } from "../interfaces/Cart";

export const compareCarts = (cart: ICart[], kilbillCart: IKilbillCart[]) => {
    if(cart.length !== kilbillCart.length) return false

    let isEqual = true

    cart.forEach(c => {
        const item = kilbillCart.find(k => k.code === c.modifiers?.article)
        if(item) {
            if(c.amount !== item.quantity) return isEqual = false 
        } else {
            return isEqual = false
        }
    })
    return isEqual
}