import { ITag } from "../interfaces/TagGroup";
export interface IPreparedTags {
    _id: string
    tags: string[]
}
export const prepareTags = (tags: ITag[]): IPreparedTags[] => {
    
    let groups: string[] = []
    tags.forEach(tag => groups = [...groups, ...tag.groups])
    groups = [...(new Set(groups))]
    const preparedTags: IPreparedTags[] = groups.map(group => {
        const _tags = tags.filter(_tag => _tag.groups.includes(group))
        return {
            _id: group,
            tags: _tags.map(t => t._id)
        }
    })
    return preparedTags
}