import { IBackendCart } from "../api/CustomerService";
import { ProductService } from "../api/ProductService";
import { ICart } from "../interfaces/Cart";
import { v4 } from 'uuid'
import { IItemModifier } from "../interfaces/Item";
export const backCartToFrontCart = async (backCart: IBackendCart[]) => {
    const api = new ProductService()
    const cart: ICart[] = []
    let products: any = {}
    for (let bc of backCart) {
        if (!products[bc.productId]) {
            const product = await api.getProductsById(bc.productId)
            products[bc.productId] = product.values[0]
        }
        cart.push({
            product: products[bc.productId],
            amount: bc.quantity,
            modifiers: products[bc.productId].modifiers.find((m: IItemModifier) => m.article === bc.modifierArticle),
            _id: v4()
        })
    }
    return cart
}

// product: IItem
//     modifiers?: IItemModifier
//     amount: number
//     _id: string