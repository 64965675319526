import classes from './miniCart.module.css'
import { Link } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useEffect, useMemo, useState } from 'react'
import { getSalesPrice, getTotalDefaultPrice } from '../../utils/getTotalPrice'
import { ICart, IKilbillCart } from '../../interfaces/Cart'
import { IOrder } from '../../interfaces/Order'
import { OrderService } from '../../api/OrderService'
import { compareCarts } from '../../utils/compareCarts'
import { Loader } from '../loader/Loader'

const MiniCart = () => {
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const [kilbilTotal, setKilbilTotal] = useState<number | null>(null)
    const [total, setTotal] = useState(0)
    const [load, setLoad] = useState(false)

    const defaultPrice = useMemo(() => {
        return getTotalDefaultPrice(cart)
    }, [cart])

    const salesPrice = useMemo(() => {
        return getSalesPrice(cart)
    }, [cart])

    const getModifierName = (c: ICart) => {
        return c.modifiers ? `${c.modifiers.name}мл` : ''
    }

    const orderService = new OrderService()

    useEffect(() => {
        setTotal(defaultPrice - salesPrice)
    }, [defaultPrice, salesPrice])

    useEffect(() => {
        const order: IOrder = {
            sourceId: '639c1b3a1ebccb09c5beb660',
            promocode: '',
            check: true,
            bonusOut: '0',
        }

        const processSale = async () => {
            let kilbilCart: IKilbillCart[] = []
            let discounted_total = 0
            let errorCounter = 0
            // let counter = 0
            setLoad(true)
            while (!compareCarts(cart, kilbilCart) && errorCounter <= 3) {
                try {
                    // counter++
                    const res = await orderService.processSale(order)
                    kilbilCart = [...res._bill_data.items]
                    discounted_total = res._bill_data.discounted_total
                } catch (error) {
                    console.log(error)
                    errorCounter++
                }
            }
            setLoad(false)
            return discounted_total
        }
        processSale().then((data) => setKilbilTotal(data))
    }, [cart])

    if (cart.length === 0) return null

    return (
        <div className={classes.miniCart}>
            <div className={classes.inner}>
                <div className={classes.lastItem}>
                    <img src={cart[cart.length - 1].product.imgUrl[0] || ''} alt={cart[cart.length - 1].product.name} className={classes.image} />
                    <div className={classes.name}>
                        <span>{cart[cart.length - 1].product.name}</span>
                        <span>{getModifierName(cart[cart.length - 1])}</span>
                    </div>
                </div>
                <div className={classes.cart}>
                    <Link to='/cart' className={classes.cartBtn}>
                        Корзина
                    </Link>
                    <div>
                        {load ? (
                            <div className={classes.loader}>
                                <Loader />
                            </div>
                        ) : kilbilTotal && kilbilTotal !== total ? (
                            <>
                                <span className={classes.price}>{kilbilTotal}</span>
                                <span className={classes.strike}>{total}</span>
                            </>
                        ) : (
                            <span className={classes.price}>{total}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MiniCart
