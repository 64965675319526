import { FC } from "react"
import Account from "../../assets/images/profile.svg"
import classes from "./profileButton.module.css"
import {Link} from "react-router-dom"

interface IProfileButton {
    isAuthorized: boolean
    balance: number
}

const ProfileButton: FC<IProfileButton> = ({isAuthorized, balance}) => {

    return (
        <Link to="/login" className={classes.btn}>
            <img src={Account} alt="Профиль" />
            {
                isAuthorized ? (
                    <span>{Math.floor(balance)}</span>
                ) : (
                    <span>Войти</span>
                )
            }
        </Link>
    )
}

export default ProfileButton