import {IItem} from "../interfaces/Item"

export const getMinPrice = (item: IItem): [number, number] => {
    if (item.modifiers && item.modifiers.length > 0) {
        let [minDefault, minSale] = [item.modifiers[0].priceDefault, item.modifiers[0].priceSale]
        for (let m of item.modifiers) {
            if (m.priceDefault < minDefault)
                minDefault = m.priceDefault

            if (m.priceSale > 0 && (m.priceSale < minSale))
                minSale = m.priceSale
        }

        return [minDefault, minSale]
    }

    if (item.components && item.components.length > 0) {
        return [item.priceDefault, item.priceSale]
    }

    return [0, 0]
}