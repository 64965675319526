import {ISearchAddress} from "../interfaces/ISearchAddress";

export class SearchAddressService {
    protected PATH = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'

    get = async(query: string, isStreet: boolean = true, city: string | undefined) => {
        const locations = isStreet ? [{fias_level: "7", city: city}] : [{fias_level: "4"}]
        const resp = await fetch(this.PATH, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Accept": "application/json",
                "Authorization": "Token 2c9b6e1678fe3844a6bbf6da79eff5364d955f8a"
            },
            body: JSON.stringify({query, locations})
        })

        const json = await resp.json() as ISearchAddress

        return json
    }

}