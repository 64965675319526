import {Api} from "./Api"
import {axiosInstance} from "./axios"
import {IPromocode} from "../interfaces/Promocode"

export class PromocodeService extends Api {
    protected PATH = 'v1/promocode'

    checkPromocode = async(promocode: string) => {
        const groups = await axiosInstance.get<IPromocode>(`${this.URL}/${this.PATH}/?promo_code=${promocode}`)
        return groups.data
    }
}