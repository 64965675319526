import React from "react"
import Item from "../item/item"
import clsx from "clsx"
import classes from "./brandGroup.module.css"
import {useNavigate} from "react-router-dom"
import {IItem} from "../../interfaces/Item"
import {getMinPrice} from "../../utils/getMinPrice"

const enum Direction {
    Left,
    Right
}

interface IBrandGroup {
    _id: string
    name: string
    image: string
    path: string
    items: Array<IItem>
    direction?: Direction
}

const BrandGroup: React.FC<IBrandGroup> = ({ _id, name, image, path, items, direction = Direction.Left }) => {

    const navigate = useNavigate()

    const onBrandClick = (e: React.MouseEvent) => {
        e.preventDefault()
        navigate(path)
    }

    return (
        <div className={clsx(classes.brand, {[classes.brandRightDirection]: direction === Direction.Right})}>
            <h3 className={classes.name} onClick={onBrandClick}>{name}</h3>
            <img src={image} alt={name} className={classes.image} onClick={onBrandClick} />
            <div className={classes.items}>
                {
                    items && items.map((i) => {
                        const [priceDefault, priceSale] = getMinPrice(i)
                        return (
                            <Item
                                key={i._id}
                                isSmall
                                isBundle={i.components.length > 0}
                                image={i.imgUrl[0]}
                                name={i.name}
                                price={priceDefault}
                                salesPrice={priceSale}
                                categories={i.categories}
                                slug={i.slug} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BrandGroup