import React from "react"
import classes from "./h.module.css"

interface IH {
    title: string
    vSpacing?: string
}

const H: React.FC<IH> = ({ title, vSpacing }) => {
    return (
        <div className={classes.header} style={{marginTop: vSpacing, marginBottom: vSpacing}}>
            <div className={classes.empty} />
            <h2>{title}</h2>
        </div>
    )
}

export default H