import {IBrand} from '../interfaces/Brand'
import {Api} from './Api'
import {axiosInstance} from './axios'
import {ProductService} from "./ProductService"

export class BrandService extends Api {
    protected PATH = 'v1/brand'

    getBrands = async(slug?: string) => {
        const groups = await axiosInstance.get<IBrand[]>(`${this.URL}/${this.PATH}/`, {
            params: {
                slug
            }
        })
        return groups.data
    }

    getBrandsForMainPage = async() => {
        const groups = await axiosInstance.get<IBrand[]>(`${this.URL}/${this.PATH}/`, {
            params: {
                showOnMainPage: true
            }
        })

        const productApi = new ProductService()

        return await groups.data.map(async (b) => {
            const products = await productApi.getProductsByBrand(b.slug, 3)
            b.products = products.values
            return b
        })
    }
}