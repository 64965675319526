import classes from './sliderGroup.module.css'
import Arrow from '../../assets/images/arrow.svg'
import React, { useState, useRef, FC, Suspense } from 'react'
import Swiper from 'react-id-swiper'
import { Await, Link } from 'react-router-dom'
import { ISale } from '../../interfaces/ISale'
import { Loader } from '../loader/Loader'
import { ErrorElement } from '../errorElement/ErrorElement'
import { DateTime } from 'ts-luxon'

type SliderGroupProps = {
    title: string
    content: Array<Promise<ISale>>
    perPage: number
}

const SliderGroup: FC<SliderGroupProps> = ({ title, content, perPage = 2 }) => {
    const [page, setPage] = useState(0)

    const sliderRef = useRef(null)

    const params = {
        slidesPerView: perPage,
        spaceBetween: 30,
        centeredSlides: true,
        slideClass: classes.event,
    }

    const onNextClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        // @ts-ignore
        if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
            // @ts-ignore
            sliderRef.current.swiper.slideNext()
        }
    }

    const onPrevClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        // @ts-ignore
        if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
            // @ts-ignore
            sliderRef.current.swiper.slidePrev()
        }
    }

    return (
        <div className={classes.itemsGroup}>
            <div className={classes.header}>
                <div className={classes.empty} />
                <h2>{title}</h2>
                <div className={classes.arrows}>
                    <button className={classes.arrowPrev} onClick={onPrevClick}>
                        <img src={Arrow} alt='Prev' />
                    </button>
                    <button className={classes.arrowNext} onClick={onNextClick}>
                        <img src={Arrow} alt='Next' />
                    </button>
                </div>
            </div>
            <div className={classes.events}>
                <Suspense fallback={<Loader />}>
                    <Await
                        resolve={content}
                        errorElement={<ErrorElement msg='Не удалось загрузить акции' />}>
                        {(promos: ISale[]) => {
                            const kdb = promos.find((p) => p.slug === 'klientskij-den-byredo')
                            const black_friday = promos.find((p) => p.slug === 'chernaya-pyatnica')
                            const dr = promos.find(
                                (p) => p.slug === 'prazdnuem-vmeste-nash-den-rozhdeniya'
                            )
                            const newPromos = promos.filter(
                                (p) =>
                                    p.slug !== 'klientskij-den-byredo' &&
                                    p.slug !== 'chernaya-pyatnica' &&
                                    p.slug !== 'prazdnuem-vmeste-nash-den-rozhdeniya'
                            )
                            if (dr) newPromos.push(dr)
                            if (kdb) newPromos.push(kdb)
                            if (black_friday) newPromos.push(black_friday)
                            return (
                                <Swiper ref={sliderRef} {...params}>
                                    {newPromos.map((promo, i) => (
                                        <Link
                                            to={`/promo/${promo.slug}`}
                                            className={classes.link}
                                            key={promo._id}>
                                            <div className={classes.event}>
                                                <img src={promo.image} alt={promo.title} />
                                                <div className={classes.eventTitle}>
                                                    <h3>{promo.title}</h3>
                                                    {/*<time>{DateTime.fromISO(promo.createdAt).toFormat("dd.LL.y")}</time>*/}
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </Swiper>
                            )
                        }}
                    </Await>
                </Suspense>
            </div>
        </div>
    )
}

export default SliderGroup
