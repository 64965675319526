import { IStory } from '../interfaces/Story'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class StoryService extends Api {
    protected PATH = 'v1/story'
    getStories = async (limit: number = 1000, offset: number = 0) => {
        const stories = await axiosInstance.get<IStory[]>(`${this.URL}/${this.PATH}?limit=${limit}&offset=${offset}`)
        return stories.data
    }
}