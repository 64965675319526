import {Api} from "./Api";
import {axiosInstance} from "./axios"
import axios from "axios"
import {IConfirmPhone} from "./LoginService"
import {ISignup} from "../interfaces/Signup"

export class SignUpService extends Api {
    protected PATH = 'v1/auth'

    confirmPhone = async(phone: string, code?: string) => {
        const groups = await axiosInstance.post<IConfirmPhone>(`${this.URL}/${this.PATH}/confirmPhone`, {
            phone,
            code
        })

        return groups.data
    }

    registration = async(form: ISignup) => {
        const groups = await axios.post(`${this.URL}/${this.PATH}/registration`, {
            ...form
        })

        return groups.data
    }

}