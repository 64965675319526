import React, { useState, useRef, TouchEvent, useCallback, useEffect } from 'react'
import useSwipe from '../../hooks/useSwipe'
import classes from './slider.module.css'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import useIsMounted from '../../hooks/useIsMounted'
import { ISlide } from '../../interfaces/Slider'

type Props = {
    slides: Array<ISlide>
}

const enum Direction {
    Left,
    Right,
    Middle,
}

const Slider: React.FC<Props> = ({ slides }) => {
    const [cursor, setCursor] = useState(Direction.Middle)
    const [index, setIndex] = useState(0)
    const sliderRef = useRef<HTMLDivElement>(null)

    const width = window.innerWidth
    const leftWidth = width / 3
    const rightWidth = width - leftWidth

    const swipeHandlers = useSwipe({
        onSwipedLeft: () => {
            onRight(index)
        },
        onSwipedRight: () => {
            onLeft(index)
        },
    })

    const onMouseMove = (e: React.MouseEvent) => {
        const { clientX } = e
        if (clientX > 0 && clientX <= leftWidth) {
            setCursor(Direction.Left)
        } else if (clientX >= rightWidth && clientX < width) {
            setCursor(Direction.Right)
        } else {
            setCursor(Direction.Middle)
        }
    }

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault()
        if (cursor === Direction.Left) {
            onLeft(index)
        } else if (cursor === Direction.Right) {
            onRight(index)
        }
    }

    const onLeft = (i: number) => {
        i = i > 0 ? --i : 0
        if (sliderRef.current !== null) {
            sliderRef.current.style.transform = `translateX(${i * -width}px)`
        }
        setIndex(i)
    }

    const onRight = (i: number) => {
        i = i < slides.length - 1 ? ++i : slides.length - 1
        if (sliderRef.current !== null) {
            sliderRef.current.style.transform = `translateX(${i * -width}px)`
        }
        setIndex(i)
    }

    const onDotClick = (e: React.MouseEvent, i: number) => {
        e.preventDefault()
        if (i < index) {
            if (sliderRef.current !== null) {
                sliderRef.current.style.transform = `translateX(${i * -width}px)`
            }
        } else {
            if (sliderRef.current !== null) {
                sliderRef.current.style.transform = `translateX(${i * -width}px)`
            }
        }
        setIndex(i)
    }

    const isMounted = useIsMounted()

    const getMediaComponent = useCallback(
        (width: number, img: string, alternate: string, poster?: string, alternatePoster?: string) => {
            const imgs = ['jpg', 'jpeg', 'png', 'webp']
            const content = width > 768 ? img : alternate !== '' ? alternate : img
            const extension = content.split('.').at(-1)
            if (imgs.includes(extension!.toLowerCase())) return <img src={content} />

            return <video preload='auto' poster={width > 768 ? poster : alternatePoster} autoPlay muted loop playsInline controls={false} src={content} />
        },
        [width, slides]
    )

    return (
        <div
            className={clsx(classes.slider, {
                [classes.sliderCursorLeft]: cursor === Direction.Left,
                [classes.sliderCursorRight]: cursor === Direction.Right,
            })}
            onMouseMove={onMouseMove}>
            <ul className={classes.dots}>
                {slides.map((s, i) => (
                    <li key={`slide_dot_${i}`} onClick={(e) => onDotClick(e, i)} className={clsx(classes.dot, { [classes.active]: index === i })} />
                ))}
            </ul>

            <div ref={sliderRef} className={clsx(classes.slides, { [classes.mounted]: isMounted })} style={{ width: `${width * slides.length}px` }}>
                {slides.map((s, i) => (
                    <div
                        key={`slide_${i}`}
                        className={classes.slide}
                        onClick={onClick}
                        {...swipeHandlers}
                        // style={{ backgroundImage: width > 768 ? `url("${s.img}")` : `url("${s.alternateUrl !== '' ? s.alternateUrl : s.img}")`, width }}
                        style={{ width }}>
                        {getMediaComponent(width, s.img, s.alternateUrl, s.poster, s.alternatePoster)}
                        <div className={classes.content}>
                            <h2>{s.title}</h2>
                            <p>{s.subtitle}</p>
                            {s.action.url.includes('http') ? (
                                <a
                                    href={s.action.url}
                                    className={classes.aBtn}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.backgroundColor = s.action.color
                                        e.currentTarget.style.color = 'white'
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.backgroundColor = 'transparent'
                                        e.currentTarget.style.color = s.action.color
                                    }}
                                    style={{ color: s.action.color, borderColor: s.action.color }}
                                    target='_blank'
                                    onClick={() => window.open(s.action.url)}
                                    rel='nofollow noopener noreferrer'>
                                    {s.action.title}
                                </a>
                            ) : (
                                <Link
                                    to={s.action.url}
                                    className={classes.aBtn}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.backgroundColor = s.action.color
                                        e.currentTarget.style.color = 'white'
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.backgroundColor = 'transparent'
                                        e.currentTarget.style.color = s.action.color
                                    }}
                                    style={{ color: s.action.color, borderColor: s.action.color }}>
                                    {s.action.title}
                                </Link>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Slider
export type { ISlide }
