import React, { useEffect, useRef, useState } from 'react'
import classes from './numberSlider.module.css'

type Props = {
    min?: number
    max?: number
    onChange: (min: number, max: number) => void
}

const NumberSlider: React.FC<Props> = ({ min = 0, max = 100_000, onChange }) => {
    const minRange = useRef<HTMLInputElement>(null)
    const maxRange = useRef<HTMLInputElement>(null)

    const [minValue, setMinValue] = useState(min)
    const [maxValue, setMaxValue] = useState(max)

    useEffect(() => {
        if (minValue > maxValue) {
            const [_min, _max] = [maxValue, minValue]
            setMinValue(_min)
            setMaxValue(_max)
            onChange(_min, _max)
        } else onChange(minValue, maxValue)
    }, [minValue, maxValue])

    useEffect(() => {
        if (min > min) {
            const [_min, _max] = [max, min]
            setMinValue(_min)
            setMaxValue(_max)
        } else {
            setMinValue(min)
            setMaxValue(max)
        }
    }, [min, max])

    return (
        <div className={classes.filter}>
            <div id='rangeSlider' className={classes.rangeSlider}>
                <strong>Цена</strong>

                <div className={classes.numberGroup}>
                    <input className={classes.numberInput} type='number' value={minValue} onChange={({ target: { value } }) => setMinValue(parseInt(value, 10))} min={0} max={100_000} />
                    <strong>﹣</strong>
                    <input className={classes.numberInput} type='number' value={maxValue} onChange={({ target: { value } }) => setMaxValue(parseInt(value, 10))} min={0} max={100_000} />
                </div>

                <div className={classes.rangeGroup}>
                    <input
                        ref={minRange}
                        className={classes.rangeInput}
                        value={minValue}
                        onChange={({ target: { value } }) => setMinValue(parseInt(value, 10))}
                        min={0}
                        max={100_000}
                        step='100'
                        type='range'
                    />
                    <input
                        ref={maxRange}
                        className={classes.rangeInput}
                        value={maxValue}
                        onChange={({ target: { value } }) => setMaxValue(parseInt(value, 10))}
                        min={0}
                        max={100_000}
                        step='100'
                        type='range'
                    />
                </div>
            </div>
        </div>
    )
}

export default NumberSlider
