import React, { Suspense } from 'react'
import { Await, Link, useLoaderData, useParams } from 'react-router-dom'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { Loader } from '../../components/loader/Loader'
import { IUserOrder, IUserOrderCartProduct } from '../../interfaces/Order'
import classes from './Profile.module.css'
import { getOrderPrice } from '../../utils/getTotalPrice'
import { IItem, IItemModifier } from '../../interfaces/Item'

type PromiseType = {
    _order: Promise<IUserOrder>
}
const Order = () => {
    const { _order } = useLoaderData() as PromiseType
    const { id } = useParams()
    const getDeliveryPrice = (cart: IUserOrderCartProduct[]) => {
        const delivery = cart.find((pr) => pr.product?.slug === 'dostavka')

        if (!delivery) return 0
        const { modifierArticle, product } = delivery
        const mod = product.modifiers.find((m) => m.article === modifierArticle)
        if (!mod) return 0
        return mod.priceDefault
    }
    return (
        <Suspense fallback={<Loader />}>
            <Await resolve={_order} errorElement={<ErrorElement msg='Не удалось загрузить заказ' />}>
                {(orders: IUserOrder[]) => {
                    const order = orders[0]
                    // const order = orders.find((o) => o._id === id)
                    if (!order)
                        return (
                            <div>
                                <h2>Мои покупки</h2>
                                <ErrorElement msg='Не удалось загрузить заказ' />
                            </div>
                        )
                    return (
                        <div>
                            <h2>Мои покупки</h2>

                            <div className={classes.orderMore}>
                                <Link className={classes.orderBack} to={'/account/orders'}>
                                    &#8592;
                                </Link>
                                <span>{order.source.name}</span>
                                {/* info */}
                                <div className={classes.orderInfo}>
                                    <span className={classes.orderInfo_code}>{order.code}</span>
                                    <div>
                                        <span>Оформлено: {new Date(order.createdAt).toLocaleDateString()}</span>
                                        <span className={order.isPayed ? classes.orderPayed : classes.orderNotPayed}>{order.isPayed ? 'Оплачено' : 'Ожидает оплаты'}</span>
                                    </div>
                                </div>
                                {/* item list */}
                                <ul className={classes.orderList}>
                                    {order.cart.map(({ product, productId, modifierArticle, quantity }) => {
                                        const modifier = product?.modifiers.find((m) => m.article === modifierArticle)
                                        return (
                                            <li key={productId}>
                                                {product ? (
                                                    product.slug !== 'dostavka' ? (
                                                        <div className={classes.productCard}>
                                                            <div className={classes.productCardImg}>
                                                                <img src={product.imgUrl[0]} />
                                                            </div>
                                                            <div className={classes.productCardInfo}>
                                                                <span>{product.name}</span>
                                                                <span>{modifier ? `${modifier.name} мл.` : ''}</span>
                                                                {product.priceSale > 0 || (modifier && modifier.priceSale > 0) ? (
                                                                    <div className={classes.productCardInfo_price}>
                                                                        <span>{modifier ? modifier.priceDefault * quantity : product.priceDefault * quantity} ₽</span>
                                                                        <span>{modifier ? modifier.priceSale * quantity : product.priceSale * quantity} ₽</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className={classes.productCardInfo_price}>
                                                                        <span>{modifier ? modifier.priceDefault * quantity : product.priceDefault * quantity} ₽</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : null
                                                ) : (
                                                    'Не удалось получить информацию по товару'
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className={classes.deliveryPrice}>
                                    Стоимость доставки: <span>{getDeliveryPrice(order.cart)} ₽</span>
                                </div>
                                <div className={classes.price}>
                                    Всего: <span>{getOrderPrice(order.cart, order.deliveryType.price)} ₽</span>
                                </div>
                                {/* other info */}
                                <div className={classes.orderOtherInfo}>
                                    <span>Номер</span>
                                    <span>{order.code}</span>
                                    <span>Дата</span>
                                    <span>{new Date(order.createdAt).toLocaleDateString()}</span>
                                    <span>Способ оплаты</span>
                                    <span>Онлайн</span>
                                    <span>Тип доставки</span>
                                    <span>{order.deliveryType.name}</span>
                                    <span>Адрес</span>
                                    <span>{order.shipmentAddress}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Await>
        </Suspense>
    )
}

export default Order
