import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom/client'
import WebFont from 'webfontloader'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import 'hamburgers/dist/hamburgers.min.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { CookiesProvider } from 'react-cookie'
import { YMInitializer } from 'react-yandex-metrika'

import Logo from './assets/images/quality.png'

WebFont.load({
    custom: {
        families: ['Stem', 'Benzin'],
    },
    events: true,
})

declare global {
    interface Window {
        dataLayer: Array<any>
        _tmr: Array<any>
    }
}

window.dataLayer = window.dataLayer || []
window._tmr = window._tmr || []

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const SiteIsDead = () => {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em',
                textAlign: 'center',
            }}>
            <img src={Logo} style={{ width: '300px' }} />
            <h1>Сайт скоро откроется!</h1>

            <div>
                <p>На сайте ведутся технические работы</p>
                <p>
                    Заказ можно оформить через онлайн-менеджера в{' '}
                    <a
                        style={{ color: 'inherit', fontSize: '1.2em' }}
                        href='https://wa.me/79139360222'>
                        WhatsApp
                    </a>
                </p>
            </div>
        </div>
    )
}
const PaymentDoesntWork = () => {
    const dialogRef = useRef<HTMLDialogElement>(null)
    return (
        <dialog
            ref={dialogRef}
            style={{
                zIndex: 99999,
                position: 'fixed',
                top: '50%',
                transform: 'translateY(-50%)',
                textAlign: 'center',
            }}
            open>
            <button
                style={{
                    border: '0',
                    backgroundColor: 'transparent',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    cursor: 'pointer',
                }}
                onClick={() => dialogRef.current?.close()}>
                X
            </button>
            <h1>Оплата заказа на сайте временно не доступна</h1>
            <p>
                Оформить заказ Вы можете через наших менеджеров написав в{' '}
                <a style={{ color: 'inherit', fontSize: '1.2em' }} href='https://wa.me/79139360222'>
                    WhatsApp
                </a>
                {': '}
                +7 913 936-02-22
            </p>
            <small>Приносим извинения за временные неудобства</small>
        </dialog>
    )
}
root.render(
    // <React.StrictMode>
    <HelmetProvider>
        <Provider store={store}>
            <CookiesProvider>
                <YMInitializer
                    accounts={[92013823]}
                    options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                        trackHash: true,
                        ecommerce: 'dataLayer',
                    }}
                    version='2'
                />
                {/* <> */}
                {/*<PaymentDoesntWork />*/}
                <App />
                {/* </> */}

                {/* <SiteIsDead /> */}
            </CookiesProvider>
        </Provider>
    </HelmetProvider>
    // </React.StrictMode>
)
