import Page from "../Page/Page"
import classes from "./NotFound.module.css"
import {Link} from "react-router-dom"

const NotFound = () => {
    return (
        <Page>
            <div className={classes.notFound}>
                <div className={classes.empty}/>
                <div className={classes.content}>
                    <h1>
                        Ого!<br/>
                        Такой страницы у нас нет.
                    </h1>

                    <p>
                        Можете посмотреть какие у нас есть<br/>
                        <Link to="/categories/aromaboksy">аромабоксы</Link> или <Link to="/categories/parfyumeriya">парфюмерия</Link></p>
                </div>
            </div>
        </Page>
    )
}

export default NotFound
