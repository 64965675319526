import {Api} from "./Api";
import {axiosInstance} from "./axios"
import {IDeliveryType} from "../interfaces/DeliveryType"

export class DeliveryTypeService extends Api {
    protected PATH = 'v1/deliveryType'

    get = async () => {
        const groups = await axiosInstance.get<IDeliveryType[]>(`${this.URL}/${this.PATH}`)
        return groups.data
    }
}