import React from 'react'
import Page from '../Page/Page'
import { Helmet } from 'react-helmet'
import classes from './ThanksForOrder.module.css'
export const ThanksForOrder = () => {
    return (
        <Page hideMiniCart>
            <Helmet>
                <title>Спасибо за заказ</title>
                <link rel='canonical' href='https://parfumeratelier.ru/thanks-for-order' />
            </Helmet>
            <div className={classes.body}>
                <h1 className={classes.center}>Спасибо за заказ ❤️</h1>
                <p className={classes.center}>Ваш заказ принят, в ближайшее время с Вами свяжется оператор для уточнения деталей доставки.</p>
                <p className={`${classes.center} ${classes.small}`}>Время работы операторов с 6:00 до 22:00 МСК</p>
                <p className={`${classes.center} ${classes.small}`}>Остались вопросы? Напишите нам!</p>
                <a className={classes.wa} href='https://wa.me/79139360222' target='_blank' rel="noreferrer noopener">
                    <svg className={classes.wa__icon} viewBox='0 0 110 110' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M86.622 23.3442C82.4963 19.2165 77.5949 15.9456 72.2 13.7198C66.8051 11.494 61.0233 10.3573 55.1873 10.375C30.6953 10.375 10.7575 30.2132 10.7475 54.6016C10.7367 62.3674 12.7837 69.9974 16.6803 76.7149L10.375 99.625L33.9326 93.4751C40.4535 97.0078 47.7531 98.8566 55.1693 98.8541H55.1873C79.6772 98.8541 99.613 79.0139 99.625 54.6275C99.6398 48.8114 98.4979 43.0505 96.2655 37.6798C94.0332 32.3092 90.7551 27.4361 86.622 23.3442ZM55.1873 91.3893H55.1713C48.56 91.3913 42.0689 89.622 36.373 86.2654L35.0243 85.4685L21.0452 89.1182L24.7765 75.5534L23.898 74.1589C20.2012 68.3062 18.2426 61.524 18.2501 54.6016C18.2501 34.3351 34.8271 17.8457 55.2012 17.8457C64.9749 17.8283 74.3552 21.6937 81.2791 28.5918C88.203 35.49 92.1034 44.8558 92.1224 54.6295C92.1145 74.898 75.5454 91.3893 55.1873 91.3893ZM75.4458 63.8593C74.3362 63.3055 68.8716 60.6319 67.8596 60.2634C66.8475 59.8948 66.1005 59.7096 65.3614 60.8172C64.6223 61.9249 62.4926 64.4032 61.8452 65.1502C61.1977 65.8973 60.5502 65.979 59.4406 65.4251C58.3309 64.8713 54.751 63.7059 50.5096 59.9406C47.2086 57.0101 44.9813 53.3923 44.3338 52.2867C43.6864 51.181 44.2641 50.5814 44.8199 50.0315C45.32 49.5355 45.9296 48.7406 46.4854 48.0951C47.0412 47.4496 47.2265 46.9874 47.595 46.2503C47.9636 45.5132 47.7803 44.8678 47.5034 44.3159C47.2265 43.7641 45.0052 38.3234 44.0808 36.1101C43.1784 33.9546 42.2639 34.2474 41.5826 34.2135C40.9352 34.1817 40.1881 34.1737 39.453 34.1737C38.891 34.1883 38.3381 34.3186 37.8287 34.5565C37.3194 34.7944 36.8645 35.1347 36.4926 35.5563C35.4746 36.6639 32.6058 39.3414 32.6058 44.7761C32.6058 50.2108 36.5902 55.4702 37.14 56.2073C37.6899 56.9444 44.9693 68.1066 56.1077 72.8939C58.1759 73.7796 60.2892 74.5563 62.4388 75.2207C65.0984 76.0614 67.5189 75.9439 69.4314 75.659C71.565 75.3423 76.0056 72.9855 76.928 70.4036C77.8504 67.8217 77.8524 65.6104 77.5755 65.1502C77.2986 64.69 76.5575 64.4111 75.4458 63.8593Z'
                            fill='white'></path>
                    </svg>
                </a>
            </div>
        </Page>
    )
}
