import Page from '../Page/Page'
import 'swiper/swiper.min.css'
import { Helmet } from 'react-helmet-async'
import Stories, { ContentProps, StoryProps } from '../../components/stories/stories'
import gClasses from '../Page/Page.module.css'
import classes from './Main.module.css'
import { BrandGroup, H, ItemsGroup, Slider, SliderGroup } from '../../components'
import { Loader } from '../../components/loader/Loader'
import { Await, useLoaderData } from 'react-router-dom'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import React, { Suspense, useEffect, useState } from 'react'
import { IBrand } from '../../interfaces/Brand'
import { IItemValue } from '../../interfaces/Item'

import { ISlide, ISlider } from '../../interfaces/Slider'
import { ISale } from '../../interfaces/ISale'

// import SummerImg from '../../assets/images/summer.jpg'
// import SvadebniiImg from '../../assets/images/svadebnii.jpg'

import AutumnImg from '../../assets/images/autumn2024.jpg'
// import WinterImg from '../../assets/images/winter.jpg'
// import ValentineImg from '../../assets/images/valentine.jpg'
// import SpringImg from '../../assets/images/spring.jpg'
import { ProductService } from '../../api/ProductService'
import { IStory } from '../../interfaces/Story'
import { StoriesSlider } from '../../components/storiesSlider/StoriesSlider'

type PromiseType = {
    brands: Array<Promise<IBrand>>
    hitProducts: Promise<IItemValue>
    newProducts: Promise<IItemValue>
    slider: Promise<ISlider>
    promos: Array<Promise<ISale>>
    stories: Array<Promise<IStory>>
}

const Main = () => {
    const { brands, hitProducts, newProducts, slider, promos, stories } =
        useLoaderData() as PromiseType
    const [summerAroma, setSummerAroma] = useState<IBrand | null>(null)

    useEffect(() => {
        const getProducts = async () => {
            try {
                const api = new ProductService()
                const _p1 = api.getProductBySlug('orlov-de-young-red')
                const _p2 = api.getProductBySlug('initio-side-effect')
                const _p3 = api.getProductBySlug('27-87-genetic-bliss')

                const [p1, p2, p3] = await Promise.all([_p1, _p2, _p3])

                setSummerAroma({
                    _id: 'osennie_aroma',
                    name: 'Осенние ароматы',
                    products: [p1.values[0], p2.values[0], p3.values[0]],
                    slug: '/categories/parfyumeriya?podborki=Osenie-aromaty',
                    imgUrl: [AutumnImg],
                })
            } catch (e) {
                console.log(e)
            }
        }

        getProducts()
    }, [])

    return (
        <Page transparentHeader={true}>
            <Suspense
            // fallback={<Loader />}
            >
                <Await
                    resolve={slider}
                    errorElement={<ErrorElement msg='Не удалось загрузить баннеры' />}>
                    {(sliders: Array<ISlider>) => {
                        return <Slider slides={sliders[0].slides} />
                    }}
                </Await>
            </Suspense>

            <div className={gClasses.inner}>
                {/*<Stories stories={stories} />*/}
                {/*<div style={{margin: "2rem 0"}} />*/}
                <Suspense>
                    <Await resolve={stories}>
                        {(_stories: IStory[]) => (
                            <StoriesSlider stories={[..._stories].reverse()} />
                        )}
                    </Await>
                </Suspense>
                <Suspense
                // fallback={<Loader />}
                >
                    <Await
                        resolve={newProducts}
                        errorElement={<ErrorElement msg='Не удалось загрузить новинки' />}>
                        {(bundles: IItemValue) => {
                            return <ItemsGroup title='Новинки' items={bundles.values} perPage={3} />
                        }}
                    </Await>
                </Suspense>

                <div className='mt-1' />
                <SliderGroup title='Акции' content={promos} perPage={1} />

                <div className='mt-1' />
                <Suspense
                // fallback={<Loader />}
                >
                    <Await
                        resolve={hitProducts}
                        errorElement={<ErrorElement msg='Не удалось загрузить хиты' />}>
                        {(bundles: IItemValue) => {
                            return <ItemsGroup title='Хиты' items={bundles.values} perPage={3} />
                        }}
                    </Await>
                </Suspense>

                {summerAroma && (
                    <>
                        <div className='mt-1' />
                        {/* <H title='Летние ароматы' vSpacing='1rem' /> */}
                        <div className={classes.brands}>
                            <BrandGroup
                                _id={summerAroma._id}
                                name={summerAroma.name}
                                path={summerAroma.slug}
                                image={summerAroma.imgUrl[0]}
                                items={summerAroma.products}
                                direction={0}
                            />
                        </div>
                    </>
                )}

                <div className='mt-1' />
                <H title='Бренды' vSpacing='1rem' />
                <div className={classes.brands}>
                    {brands.map((p, i) => (
                        <Suspense
                            key={`promise_${i}`}
                            // fallback={<Loader />}
                        >
                            <Await
                                resolve={p}
                                errorElement={<ErrorElement msg='Не удалось загрузить бренды' />}>
                                {(b: IBrand) => {
                                    return (
                                        <BrandGroup
                                            _id={b._id}
                                            key={b._id}
                                            name={b.name}
                                            path={`/brands/${b.slug}`}
                                            image={b.imgUrl[0]}
                                            items={b.products}
                                            direction={i % 2}
                                        />
                                    )
                                }}
                            </Await>
                        </Suspense>
                    ))}
                </div>

                <div className='mt-1' />
                {/*<SliderGroup title="Новости" slides={[Test3, Test4]} />*/}
            </div>
        </Page>
    )
}

const content: ContentProps = {
    contentType: 'image',
    content: '',
}

const story1: StoryProps = {
    preview: '',
    contents: [content],
}

const story2: StoryProps = {
    preview: 'https://storage.yandexcloud.net/parfum.public/stories/story_cover_21.07.22_okolo.jpg',
    contents: [content],
}

const story3: StoryProps = {
    preview: 'https://storage.yandexcloud.net/parfum.public/stories/story_cover_21.07.22_okolo.jpg',
    contents: [content],
}

const story4: StoryProps = {
    preview: 'https://storage.yandexcloud.net/parfum.public/stories/story_cover_21.07.22_okolo.jpg',
    contents: [content],
}

const stories: Array<StoryProps> = [story1, story2, story3, story4]

export default Main
