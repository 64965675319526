import React, { useEffect, useMemo, useState } from 'react'
import classes from './itemsGrid.module.css'

import Item from '../item/item'
import { IItem } from '../../interfaces/Item'
import { ITag } from '../../interfaces/TagGroup'
import { prepareTags } from '../../utils/prepareTags'
import { getMinPrice } from '../../utils/getMinPrice'
import { IBrand } from '../../interfaces/Brand'
import clsx from 'clsx'
import { ISortType } from '../sidebar/sidebar'

type Props = {
    items: Array<IItem>
    filters: ITag[]
    filterPrice: number[]
    pickedBrands: Array<IBrand>
    fromSearch: boolean
    sortType?: ISortType
}

const ItemsGrid: React.FC<Props> = ({
    items,
    filters,
    filterPrice,
    pickedBrands,
    fromSearch = false,
    sortType,
}) => {
    const [_items, setItems] = useState<IItem[]>(items)

    useEffect(() => {
        setItems(items)
    }, [items])

    useEffect(() => {
        const pickedBrandsIds = pickedBrands.map((item) => item._id)
        const preparedFilters = prepareTags(filters)

        setItems(
            items.filter((item) => {
                const [priceDefault, priceSale] = getMinPrice(item)
                //check price
                if (
                    filterPrice[1] !== -1 &&
                    (priceDefault < filterPrice[0] || priceDefault > filterPrice[1])
                )
                    return false
                //check brands
                if (pickedBrandsIds.length > 0 && !pickedBrandsIds.includes(item.brands[0]?._id))
                    return false

                //check tags
                let isFound = true
                const isFoundedTags = []
                const { tags } = item
                const preparedTags = prepareTags(tags)
                // if (item.article === '71530') console.log(item, preparedTags, preparedFilters)
                for (const group of preparedFilters) {
                    const preparedTag = preparedTags.find((f) => f._id === group._id)
                    //63526b9a845f00f43c559786 - унисекс
                    //"63452467c94f4f573e5fd760" - dlya kogo

                    if (!preparedTag) {
                        isFound = false
                        isFoundedTags.push(false)
                        continue
                    }

                    if (
                        group._id === '63452467c94f4f573e5fd760' &&
                        !group.tags.includes('63526b9a845f00f43c559786')
                    ) {
                        group.tags.push('63526b9a845f00f43c559786')
                    }
                    console.log(group)
                    let isTagFound = false
                    preparedTag.tags.forEach((pt) => {
                        if (group.tags.includes(pt)) isTagFound = true
                    })
                    isFoundedTags.push(isTagFound)
                    isFound = isTagFound
                }
                // console.log(isFoundedTags)
                // return isFound
                return !isFoundedTags.includes(false)
            })
        )
    }, [filters, filterPrice, pickedBrands])

    const sortedItems = useMemo(() => {
        if (!sortType) return _items
        switch (sortType.id) {
            case 'popular':
                return [..._items].sort(sortByPopular)
            case 'sale':
                return [..._items].sort(sortBySale)
            case 'price_desc':
                return [..._items].sort(sortByPriceDesc)
            case 'price_asc':
                return [..._items].sort(sortByPriceAsc)
            case 'name_desc':
                return [..._items].sort(sortByNameDesc)
            case 'name_asc':
                return [..._items].sort(sortByNameAsc)
            default:
                return _items
        }
    }, [sortType, _items])

    return (
        <div className={clsx(classes.items, { [classes.search]: fromSearch })} data-block='items'>
            {_items.length > 0 ? (
                sortedItems.map((i) => {
                    const [priceDefault, priceSale] = getMinPrice(i)
                    return (
                        <Item
                            key={i._id}
                            image={i.imgUrl[0]}
                            name={i.name}
                            price={priceDefault}
                            salesPrice={priceSale}
                            categories={i.categories}
                            slug={i.slug}
                            badges={i.badges}
                        />
                    )
                })
            ) : (
                <p>В данном разделе ничего</p>
            )}
        </div>
    )
}

export default ItemsGrid

/*
        Filters
    */
const sortByPopular = (a: IItem, b: IItem) => {
    return 0
}
const sortBySale = (a: IItem, b: IItem) => {
    return 0
}
const sortByPriceAsc = (a: IItem, b: IItem) => {
    if (a.priceDefault && b.priceDefault) {
        return a.priceDefault - b.priceDefault
    }

    if (a.modifiers.length > 0 && b.modifiers.length > 0) {
        const minAModifierPrice = a.modifiers.sort((a, b): number => {
            return a.priceDefault - b.priceDefault
        })[0].priceDefault

        const minBModifierPrice = b.modifiers.sort((a, b): number => {
            return a.priceDefault - b.priceDefault
        })[0].priceDefault

        return minAModifierPrice - minBModifierPrice
    }

    return 0
}
const sortByPriceDesc = (a: IItem, b: IItem) => {
    if (a.priceDefault && b.priceDefault) {
        return b.priceDefault - a.priceDefault
    }

    if (a.modifiers.length > 0 && b.modifiers.length > 0) {
        const minAModifierPrice = a.modifiers.sort((a, b): number => {
            return b.priceDefault - a.priceDefault
        })[0].priceDefault

        const minBModifierPrice = b.modifiers.sort((a, b): number => {
            return b.priceDefault - a.priceDefault
        })[0].priceDefault

        return minBModifierPrice - minAModifierPrice
    }

    return 0
}

const sortByNameAsc = (a: IItem, b: IItem) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
}
const sortByNameDesc = (a: IItem, b: IItem) => {
    if (a.name < b.name) return 1
    if (a.name > b.name) return -1
    return 0
}
