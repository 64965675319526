import { useEffect, useState } from 'react'

export const useImage = (src: string, placeholder: string) => {
    const [imgSrc, setImgSrc] = useState(placeholder)

    useEffect(() => {
        const image = new Image()
        image.src = src
        image.onload = () => {
            setImgSrc(src || placeholder)
        }
    }, [src])

    return {
        imgSrc,
    }
}
