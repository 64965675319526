import axios from 'axios'
import { Api } from './Api'
import {axiosInstance} from "./axios"

export interface IConfirmPhone {
    code?: string
    token?: string
}

export class LoginService extends Api {
    protected PATH = 'v1/auth'

    login = async(phone: string, code?: string) => {
        const groups = await axios.post<IConfirmPhone>(`${this.URL}/${this.PATH}/login`, {
            phone,
            code
        })

        return groups.data
    }

}