import {Api} from "./Api"
import {axiosInstance} from "./axios"
import {IRetailPoint} from "../interfaces/RetailPoint"

export class RetailPointService extends Api {
    protected PATH = 'v1/retail'

    get = async() => {
        const groups = await axiosInstance.get<IRetailPoint[]>(`${this.URL}/${this.PATH}/`)
        return groups.data
    }
}