import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper'
import { IStory } from '../../interfaces/Story'
import { Stories } from './Stories'
import cls from './StoriesSlider.module.css'

interface StorySliderProps {
    activeStoryIndex: number
    stories: IStory[]
    close: () => void
}

export const StorySliderFullscreen = ({ activeStoryIndex, stories, close }: StorySliderProps) => {
    const sliderRef = useRef(null)
    const [isMuted, setMuted] = useState(false)
    const [activeIndex, setActiveIndex] = useState(activeStoryIndex)
    const params: ReactIdSwiperProps = {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        activeSlideKey: activeIndex.toString(),
        spaceBetween: 30,
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },
        on: {
            slideChange: (swiper) => {
                setActiveIndex(swiper.activeIndex)
            },
        },
    }

    return createPortal(
        <div className={cls.StoryWrapper}>
            <div className={cls.Close} onClick={close}></div>
            <div className={cls.Swiper}>
                <Swiper {...params} ref={sliderRef}>
                    {stories.map((s, i) => (
                        <div key={i} className={cls.SlideWrapper}>
                            <Stories
                                isActive={i === activeIndex}
                                slides={s.slides}
                                name={s.name}
                                preview={s.preview}
                                date={s.updatedAt}
                                muted={isMuted}
                                setMuted={() => setMuted((prev) => !prev)}
                                //@ts-ignore
                                slidePrev={() => sliderRef.current!.swiper.slidePrev()}
                                onAllSlidesEnd={() => {
                                    const viewed =
                                        localStorage.getItem('viewedStories')?.split(',') || ''
                                    if (!viewed.includes(s._id))
                                        localStorage.setItem(
                                            'viewedStories',
                                            [...viewed, s._id].join(',')
                                        )
                                    if (activeIndex === stories.length - 1) {
                                        close()
                                        return
                                    }
                                    //@ts-ignore
                                    sliderRef.current!.swiper.slideNext()
                                }}
                            />
                        </div>
                    ))}
                </Swiper>
            </div>
        </div>,
        document.getElementById('stories')!
    )
}
